import { createConfig, http } from '@wagmi/core';
import { bsc, bscTestnet, goerli, mainnet } from 'wagmi/chains';
import { coinbaseWallet, metaMask, walletConnect } from 'wagmi/connectors';
export const config = createConfig({
  chains: [mainnet, bsc, bscTestnet, goerli],
  connectors: [
    metaMask(),
    coinbaseWallet({
      appName: 'Planetex.io',
    }),
    walletConnect({
      projectId: '3c4f8c2124439727876ab8f3e39dfa1e',
    }),
  ],
  transports: {
    [mainnet.id]: http(
      'https://rpc.ankr.com/eth/1b0eb311935eb26367022aba381e63c9b37cc27c7aa5469e0c0aabd7a400246a'
    ),
    [goerli.id]: http(),
    [bsc.id]: http(
      'https://rpc.ankr.com/bsc/1b0eb311935eb26367022aba381e63c9b37cc27c7aa5469e0c0aabd7a400246a'
    ),
    [bscTestnet.id]: http(),
  },
});
