import { DEXToken, TradeType, ethers, utils } from '@omisoftnet/game-dex-sdk';
import {
  AlphaRouter,
  CurrencyAmount,
  SwapOptions,
  SwapRoute,
  SwapType,
  nativeOnChain,
} from '@uniswap/smart-order-router';
import { Percent } from '@uniswap/sdk-core';
import ISwapRouter02ABI from '@abis/swapRouterV2.json';
import { Interface } from '@ethersproject/abi';
import JSBI from 'jsbi';
import { useMemo, useState } from 'react';

import { isRejected, setIsRejected } from 'components/SwapModal/SwapModalNext';
import { stableTokens } from 'helpers/nativeTokens';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'state';
import {
  firstTokenSelector,
  isDataLoadingSelected,
  secondTokenSelector,
  swapSettingsSelector,
} from 'state/swap/selectors';
import { setIsDataLoading } from 'state/swap/slice';
import { useAccount, useBlockNumber } from 'wagmi';
import { useSigner } from './useSigner';
import { Protocol } from '@uniswap/router-sdk';
import { useQuery } from '@tanstack/react-query';
type ProviderList = {
  [key: number]: ethers.providers.JsonRpcProvider;
};
const ONE_HUNDRED_PERCENT = new Percent('1');
const THIRTY_BIPS_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000));
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(THIRTY_BIPS_FEE);
const providers: ProviderList = {
  [1]: new ethers.providers.JsonRpcProvider(
    'https://rpc.ankr.com/eth/1b0eb311935eb26367022aba381e63c9b37cc27c7aa5469e0c0aabd7a400246a'
  ),
  [56]: new ethers.providers.JsonRpcProvider(
    'https://rpc.ankr.com/bsc/1b0eb311935eb26367022aba381e63c9b37cc27c7aa5469e0c0aabd7a400246a'
  ),
  [5]: new ethers.providers.JsonRpcProvider(
    'https://ethereum-goerli.publicnode.com'
  ),
  [97]: new ethers.providers.JsonRpcProvider(
    'https://bsc-testnet.publicnode.com'
  ),
};
export type QuoteParamaters = {
  refetch?: boolean;
  setInsuficientLiquidity: (value: boolean) => void;
  insuficientLiquidity: boolean;
};

// A shitty code need to fix in sdk for return NativeToken
function getToken(token: DEXToken): any {
  return token.isNative ? nativeOnChain(token.chainId) : token!.wrapped.into();
}
export let rejectPromise: (reason: any) => void;
export const useQuote = (params?: QuoteParamaters) => {
  const dispatch = useDispatch();
  const { address, chain } = useAccount();
  const signer = useSigner({ chainId: chain?.id ?? 1 });
  const router = useMemo(() => {
    const router = new AlphaRouter({
      chainId: chain?.id === 97 || !chain?.id ? 1 : chain?.id,
      provider: providers[chain?.id ?? 1],
    });
    return router;
  }, [providers]);
  console.log('router', router);
  const firstTokenSelected = useSelector(firstTokenSelector);
  const secondTokenSelected = useSelector(secondTokenSelector);
  const [isBlockNumberEnabled, setIsBlockNumberEnabled] = useState(false);
  const [timeoutIdState, setTimeoutId] = useState<NodeJS.Timeout>();
  const isLoading = useSelector(isDataLoadingSelected);
  // const { refetch } = useBlockNumber({
  //   chainId: chain?.id,
  //   watch: true,
  //   cacheTime: 40_000,
  //   query: {
  //     enabled: isBlockNumberEnabled,
  //     gcTime: 40_000,
  //     staleTime: 10000,
  //   },
  // });
  // refetch();
  // const timeoutId = setTimeout(() => {
  //   params?.refetch &&
  //     !isLoading &&
  //     !params.insuficientLiquidity &&
  //     !timeoutIdState &&
  //     quote(undefined, false, firstTokenSelected, secondTokenSelected);
  // }, 10_000);
  // setTimeoutId(timeoutId);
  const { status, data, error, isFetching } = useQuery({
    queryKey: ['quote'],
    queryFn: () => {
      console.log('firstTokenSelected', firstTokenSelected);
      console.log('secondTokenSelected', secondTokenSelected);
      if (
        !isLoading &&
        !params?.insuficientLiquidity &&
        firstTokenSelected &&
        secondTokenSelected
      ) {
        quote(undefined, false, firstTokenSelected, secondTokenSelected);
      }
    },
    refetchInterval: 10000,
    enabled:
      !!firstTokenSelected &&
      !!secondTokenSelected &&
      !firstTokenSelected.amount.isZero(),
  });
  const [route, setRoute] = useState<SwapRoute | null>(null);
  const [quoteWorks, setQuoteWorks] = useState<boolean>(false);
  const swapSettings = useSelector(swapSettingsSelector);
  const quote = (
    options?: SwapOptions,
    isExactOutput?: boolean,
    tokenIn?: DEXToken,
    tokenOut?: DEXToken,
    primaryCall?: boolean
  ): Promise<SwapRoute | null> => {
    return new Promise(async (resolve, reject) => {
      rejectPromise = reject;
      if (
        (tokenIn === undefined && tokenOut === undefined) ||
        tokenIn?.address === tokenOut?.address ||
        (isLoading && !primaryCall)
      ) {
        return;
      }
      if (!isExactOutput && tokenIn?.amount.isZero()) {
        dispatch(setIsDataLoading(false));
        return;
      }
      if (isExactOutput && tokenOut?.amount.isZero()) {
        dispatch(setIsDataLoading(false));
        return;
      }
      try {
        setIsBlockNumberEnabled(false);
        setQuoteWorks(true);
        dispatch(setIsDataLoading(true));
        if (options) options.recipient = address!;
        const currencyIn = CurrencyAmount.fromRawAmount(
          getToken(isExactOutput ? tokenOut! : tokenIn!),
          isExactOutput
            ? tokenOut!.amount.toHexString()
            : tokenIn!.amount.toHexString()
        );
        // const cake = await SmartRouter.getBestTrade(
        //   currencyIn as any,
        //   getToken(tokenOut!),
        //   TradeType.EXACT_INPUT,
        //   {
        //     quoteProvider: SmartRouter.createQuoteProvider(),
        //     gasPriceWei: '',
        //     poolProvider: SmartRouter.createPoolProvider({onChainProvider: providers[chain?.id ?? 56]})
        //   }
        // );

        const config = options ?? {
          recipient: address!,
          slippageTolerance: swapSettings.slippage
            ? swapSettings.slippage
            : new Percent(50, 10_000),
          deadline: Math.floor(Date.now() / 1000 + 1800),
          type: SwapType.SWAP_ROUTER_02,
        };

        const _r = router.route(
          currencyIn.multiply(INPUT_FRACTION_AFTER_FEE),
          getToken(isExactOutput ? tokenIn! : tokenOut!),
          isExactOutput ? TradeType.EXACT_OUTPUT : TradeType.EXACT_INPUT,
          config,
          { protocols: [Protocol.V3] }
        );
        const FEE_BOSS = '0x31C1056C5c820c89a27BF3831FC93e881445A153';
        // if chain == mainnet or bsc (return client wallet) else EBAF (test account)
        // chain && (chain!.id === 56 || chain!.id === 1)
        //   ? '0x31C1056C5c820c89a27BF3831FC93e881445A153'
        //   : '0x801F0Ac608f9CE61a5A83DF032F6495598BcEBAf';
        // dangerous
        const _c = router.route(
          tokenIn?.address !== stableTokens[chain?.id || 1].address
            ? currencyIn.subtract(currencyIn.multiply(INPUT_FRACTION_AFTER_FEE))
            : CurrencyAmount.fromRawAmount(
                getToken(tokenOut!),
                tokenOut!.amount.toHexString()
              ),
          isExactOutput ? tokenIn!.into() : stableTokens[chain?.id || 1].into(),
          isExactOutput ? TradeType.EXACT_OUTPUT : TradeType.EXACT_INPUT,
          {
            ...config,
            recipient: FEE_BOSS,
          },
          { protocols: [Protocol.V3] }
        );
        const [r, c] = await Promise.all([_r, _c]);

        if (r && c) {
          r!.methodParameters!.calldata = combineRoutes([r, c]);
          r.estimatedGasUsedUSD = r.estimatedGasUsedUSD.add(
            c.estimatedGasUsedUSD
          );
          r.estimatedGasUsed = r.estimatedGasUsed.add(c.estimatedGasUsed);
          r.methodParameters!.value = ethers.BigNumber.from(
            r.methodParameters?.value
          )
            .add(ethers.BigNumber.from(c.methodParameters?.value))
            .toHexString();
          // @ts-ignore I no have another way to do this, this dont make bug with pop up numbers
          // r.trade._inputAmount = currencyIn;
        }
        console.log('qoute works');
        if (!r) {
          params?.setInsuficientLiquidity(true);
          setIsBlockNumberEnabled(false);
          clearTimeout(timeoutIdState);
          setTimeoutId(undefined);
        }

        if (r) {
          params?.setInsuficientLiquidity(false);
        }
        if (isRejected) {
          resolve(null);
          dispatch(setIsDataLoading(true));

          return;
        }
        if (
          tokenIn?.address !== store.getState().token.firstToken?.address ||
          tokenOut?.address !== store.getState().token.secondToken?.address ||
          swapSettings.slippage.toFixed() !==
            store.getState().token.swapSettings.slippage.toFixed()
        ) {
          dispatch(setIsDataLoading(true));
          return;
        }
        setRoute(r);
        dispatch(setIsDataLoading(false));
        setQuoteWorks(false);
        console.log(r);
        resolve(r);
      } catch (err) {
        console.error(err);
        setIsRejected(false);
        dispatch(setIsDataLoading(false));
        setIsBlockNumberEnabled(false);
        clearTimeout(timeoutIdState);
      } finally {
        setIsRejected(false);
        setIsBlockNumberEnabled(true);
        clearTimeout(timeoutIdState);
        setTimeoutId(undefined);
      }
    });
  };

  // Quote existent trade if exists
  const aquote = useMemo(() => {
    // if (!route) return;
    return route?.trade.executionPrice.quote(
      CurrencyAmount.fromRawAmount(
        route.trade.executionPrice.baseCurrency,
        utils
          .parseUnits('1', route?.trade?.executionPrice?.baseCurrency?.decimals)
          .toHexString()
      )
    );
  }, [route]);

  return {
    route,
    isLoading,
    aquote,
    quote,
    quoteWorks,
  };
};

const combineRoutes = (routes: Array<SwapRoute>) => {
  const iface = new Interface(ISwapRouter02ABI);
  const functionSignature = 'multicall(uint256,bytes[])';
  // let commands = '0x';
  const inputs = [];
  for (const r of routes) {
    const [, _inputs] = iface.decodeFunctionData(
      functionSignature,
      r!.methodParameters!.calldata
    );

    // console.log('inputs', _inputs);
    // commands += _commands.slice(2); // Remove prefix 0x
    inputs.push(..._inputs);
  }
  // console.log(commands, inputs);
  const calldata = iface.encodeFunctionData(functionSignature, [
    Math.floor(Date.now() / 1000 + 1800),
    inputs,
  ]);
  return calldata;
};
