import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { FileUploader } from 'react-drag-drop-files';
import { ArrowBack, Delete, Language } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { utils } from '@omisoftnet/game-dex-sdk';

import {
  ButtonContainer,
  ContainerWrapper,
  Description,
  NetworkIcon,
  PaddingContainer,
  RequiredStar,
  StyledButton,
  StyledConatiner,
  StyledCustomInput,
  StyledCustomInputContainer,
  StyledHeadingContainer,
  StyledIconButton,
  StyledInput,
  StyledInputContainer,
  StyledInputLabel,
  StyledLinksContainer,
  StyledLinksInput,
  StyledNetworkSelect,
  StyledSection,
  StyledTitle,
  Subtitle,
  ErrorContainer,
  ErrorText,
  TotalErr,
  UploadArea,
  UploadAreaContainer,
  UploadImage,
  UploadText,
} from './styles';
import { createNftCollection } from 'config/api';
import uploadImage from '../../shared/upload-image.svg';
import { ClearAllBtn } from 'components/AddLiquidity/styles';
import { Color } from 'helpers/themeStyles';
import { PageRoutes } from 'config/routes';
import { fileTypes } from 'helpers/fileTypes';
import {
  selectMenuListStyle,
  selectPaperStyle,
  StyledError,
  StyledFormControl,
  StyledMenuItem,
} from 'components/CreateNft/styles';
import LiquidityWaitingPopup from 'components/LiquidityWaitingPopup';
import getNetworkIcon from 'helpers/getNetworkIcon';
import NFT_MARKETPLACE from 'config/contracts';
import AlertIcon from 'svg/AlertIcon';
import { useAccount, useSwitchChain } from 'wagmi';
import { isChainSupproted } from 'helpers/isChainSupproted';

interface CreateCollectionValues {
  name: string;
  slug: string;
  owner: string;
  primary_asset_contract: string;
  image: string | Blob;
  image_banner: string | Blob;
  description: string;
  network: string;
  royalties_percent: string;
  royalties_address: string;
}

const CreateNftCollection = () => {
  const { t } = useTranslation();
  const { chains } = useSwitchChain();
  const { chain } = useAccount();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [isOpen, setIsOpen] = useState(false);
  const defaultSite = 'https://sierraswap.com/';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const [countFields, setCountFields] = useState(1);
  const [imageUrl, setImageUrl] = useState('');
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [typeBannerError, setTypeBannerError] = useState(false);
  const [sizeBannerError, setSizeBannerError] = useState(false);
  const [keyCounter, setKeyCounter] = useState(0);
  const [bannerUrl, setBannerUrl] = useState('');
  const [image, setImage] = useState<File>();
  const [bannerImage, setBannerImage] = useState<File>();
  const [charactersNameLeft, setCharactersNameLeft] = useState(25);
  const [descriptionCharactersLeft, setDescriptionCharactersLeft] =
    useState(1000);
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [isOpenNetworkSelect, setIsOpenNetworkSelect] =
    useState<boolean>(false);
  const [isAddressErr, setAddressErr] = useState(false);
  const [uniqueNamingError, setUniqueNamingError] = useState(false);
  const [creatingError, setCreatingError] = useState(false);
  const isDevelopmentMode = process.env.NODE_ENV === 'development';

  const generateNetworksArray = () => {
    if (!isDevelopmentMode) {
      return chains;
    }
    return chains.filter((network) => !network.name.includes('Testnet'));
  };
  async function createCollection(values: CreateCollectionValues) {
    const NFTExchanger =
      NFT_MARKETPLACE[
        !values.network ? Number(chain?.id!) : Number(values.network)
      ].market;
    try {
      setUniqueNamingError(false);
      setCreatingError(false);
      setOpenWaitingPopup(true);
      const form = new FormData();
      form.append('name', values.name);
      form.append('slug', values.slug);
      form.append('owner', values.owner);
      form.append('image', values.image);
      form.append('image_banner', values.image_banner);
      form.append('primary_asset_contract', NFTExchanger);
      form.append('description', values.description);
      form.append(
        'chain_id',
        !values.network ? String(chain?.id!) : values.network
      );
      form.append('royalties_address', values.royalties_address);
      form.append('royalties_percent', values.royalties_percent);
      const result = await createNftCollection('/nft-collection/create', form);

      formik.resetForm();
      if (imageUrl) {
        setImageUrl('');
        setImage(undefined);
      }
      if (bannerUrl) {
        setBannerUrl('');
        setBannerImage(undefined);
      }
      setOpenWaitingPopup(false);
      navigate(PageRoutes.MyNfts);
    } catch (error) {
      console.log(error); // for debug
      setOpenWaitingPopup(false);
      if (
        error.response?.data?.error &&
        error.response.data.error === 'NFT Collection already exists'
      ) {
        setUniqueNamingError(true);
      } else {
        setCreatingError(true);
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
      owner: localStorage.getItem('userId')!,
      primary_asset_contract: '',
      image: '',
      description: '',
      image_banner: '',
      network: '',
      royalties_address: '',
      royalties_percent: '',
    },
    onSubmit: (values) => {
      createCollection(values);
    },
  });
  useEffect(() => {
    if (image) {
      setImageUrl(URL.createObjectURL(image));
    }
  }, [image]);
  useEffect(() => {
    if (bannerImage) {
      setBannerUrl(URL.createObjectURL(bannerImage));
    }
  }, [bannerImage]);

  useEffect(() => {
    setCountFields(1);
  }, [countFields]);
  const clearAll = () => {
    formik.resetForm();
    setImageUrl('');
    setBannerUrl('');
    setImage(undefined);
    setBannerImage(undefined);
    setSizeBannerError(false);
    setTypeBannerError(false);
    setSizeError(false);
    setTypeError(false);
    setKeyCounter((prevCounter) => prevCounter + 1);
    setCharactersNameLeft(25);
    setDescriptionCharactersLeft(1000);
  };
  const validateCreatorFeesAddress = async (address: string) => {
    try {
      if (address.trim()) {
        utils.getAddress(address);
        setAddressErr(false);
      } else {
        setAddressErr(false);
      }
    } catch {
      setAddressErr(true);
    }
  };
  const disabled =
    !formik.values.image ||
    !formik.values.image_banner ||
    !formik.values.name ||
    isAddressErr ||
    !isChainSupproted(chain?.id!);
  const compressedFile = async (file: File) => {
    const imageBitmap = await createImageBitmap(file);
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx!.drawImage(imageBitmap, 0, 0);

    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, file.type, 0.5)
    );
    const compressedFile = new File([blob as BlobPart], file.name, {
      type: (blob as File).type,
    });
    return compressedFile;
  };
  return (
    <>
      <ContainerWrapper>
        <StyledConatiner>
          <StyledHeadingContainer>
            <Stack
              direction='row'
              gap='16px'
            >
              <StyledIconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </StyledIconButton>
              <StyledTitle>{t('createCollection')}</StyledTitle>
            </Stack>
            <ClearAllBtn onClick={() => clearAll()}>
              {t('clearAll')}
            </ClearAllBtn>
          </StyledHeadingContainer>
          <form
            onSubmit={formik.handleSubmit}
            name='form'
            style={{ width: isMobile ? '100%' : 'inherit', paddingTop: '16px' }}
          >
            <PaddingContainer>
              <StyledSection>
                <Subtitle>
                  {t('logoImage')} <span style={{ color: Color.PINK }}>*</span>
                </Subtitle>
                <Description>
                  {t('logoImageDescription')} {t('fileTypeSupported')}{' '}
                  <span
                    style={{
                      color: typeError ? Color.RED : Color.TEXT_GRAY_SECONDARY,
                    }}
                  >
                    {t('fileTypes')}
                  </span>{' '}
                  <span
                    style={{
                      color: sizeError ? Color.RED : Color.TEXT_GRAY_SECONDARY,
                    }}
                  >
                    {t('maxSize')}
                  </span>
                </Description>
                <UploadAreaContainer>
                  <FileUploader
                    key={keyCounter}
                    handleChange={async (file: File) => {
                      setTypeError(false);
                      setSizeError(false);
                      if (!file.type.includes('webp' || 'gif')) {
                        const canvasFile = await compressedFile(file);
                        formik.setFieldValue('image', canvasFile);
                        setImage(canvasFile);
                      } else {
                        formik.setFieldValue('image', file);
                        setImage(file);
                      }
                    }}
                    name='form'
                    types={fileTypes}
                    onTypeError={() => {
                      setTypeError(true);
                    }}
                    onSizeError={() => {
                      setSizeError(true);
                    }}
                    maxSize={50}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        width={'100%'}
                        height={'100%'}
                        style={{
                          objectFit: 'cover',
                        }}
                        alt=''
                      />
                    ) : (
                      <UploadArea>
                        <UploadImage src={uploadImage} />
                        <UploadText>{t('dragYourNftHere')}</UploadText>
                      </UploadArea>
                    )}
                  </FileUploader>
                  {typeError && <StyledError>{t('fileTypeError')}</StyledError>}
                  {sizeError && <StyledError>{t('fileSizeError')}</StyledError>}
                </UploadAreaContainer>
              </StyledSection>
              <StyledSection>
                <Subtitle>
                  {t('bannerImage')}{' '}
                  <span style={{ color: Color.PINK }}>*</span>
                </Subtitle>
                <Description>
                  {t('bannerImageDescription')} {t('fileTypeSupported')}{' '}
                  <span
                    style={{
                      color: typeBannerError
                        ? Color.RED
                        : Color.TEXT_GRAY_SECONDARY,
                    }}
                  >
                    {t('fileTypes')}
                  </span>{' '}
                  <span
                    style={{
                      color: sizeBannerError
                        ? Color.RED
                        : Color.TEXT_GRAY_SECONDARY,
                    }}
                  >
                    {t('maxSize')}
                  </span>
                </Description>
                <UploadAreaContainer>
                  <FileUploader
                    key={keyCounter}
                    handleChange={async (file: File) => {
                      setTypeBannerError(false);
                      setSizeBannerError(false);
                      if (!file.type.includes('webp' || 'gif')) {
                        const canvasFile = await compressedFile(file);
                        formik.setFieldValue('image_banner', canvasFile);
                        setBannerImage(file);
                      } else {
                        formik.setFieldValue('image_banner', file);
                        setBannerImage(file);
                      }
                    }}
                    name='form'
                    types={fileTypes}
                    onTypeError={() => {
                      setTypeBannerError(true);
                    }}
                    onSizeError={() => {
                      setSizeBannerError(true);
                    }}
                    maxSize={50}
                  >
                    {bannerUrl ? (
                      <img
                        src={bannerUrl}
                        width={'100%'}
                        height={'100%'}
                        style={{
                          objectFit: 'cover',
                        }}
                        alt=''
                      />
                    ) : (
                      <UploadArea>
                        <UploadImage src={uploadImage} />
                        <UploadText>{t('dragYourNftHere')}</UploadText>
                      </UploadArea>
                    )}
                  </FileUploader>
                  {typeBannerError && (
                    <StyledError>{t('fileTypeError')}</StyledError>
                  )}
                  {sizeBannerError && (
                    <StyledError>{t('fileSizeError')}</StyledError>
                  )}
                </UploadAreaContainer>
              </StyledSection>
              <UploadAreaContainer>
                <StyledInputContainer
                  style={{ marginBottom: '16px' }}
                  sx={{ mb: '16px' }}
                >
                  <Subtitle>
                    {t('name')} <RequiredStar>*</RequiredStar>
                  </Subtitle>
                  <StyledInput
                    name='name'
                    value={formik.values.name}
                    placeholder={t('nameExample')!}
                    required
                    size='small'
                    onChange={(event) => {
                      const value = event.target.value;
                      const remaining = 25 - value.length;
                      if (remaining >= 0) {
                        formik.setFieldValue('name', value);
                        setCharactersNameLeft(remaining);
                      }
                    }}
                  />
                  <Description
                    sx={{
                      color: charactersNameLeft ? Color.WHITE : Color.RED,
                      mb: 0,
                      textAlign: 'right',
                    }}
                  >
                    {t('symbolsLeft', { count: charactersNameLeft })}
                  </Description>
                </StyledInputContainer>

                <StyledInputContainer>
                  <Subtitle>{t('url')}</Subtitle>
                  <Description>{t('urlDescription')}</Description>
                  <StyledCustomInputContainer>
                    <div>{defaultSite}</div>
                    <StyledCustomInput
                      name='url'
                      value={formik.values.slug}
                      onChange={(e) => {
                        formik.setFieldValue('slug', e.target.value);
                      }}
                      size='small'
                    />
                  </StyledCustomInputContainer>
                </StyledInputContainer>
                <StyledInputContainer sx={{ marginBottom: 0 }}>
                  <Subtitle>{t('description')}</Subtitle>
                  <Description
                    sx={{
                      color: descriptionCharactersLeft
                        ? Color.WHITE_OPACITY_LIGHT
                        : Color.RED,
                    }}
                  >
                    {t('descriptionOfDescription')}
                  </Description>
                  <StyledInput
                    name='description'
                    value={formik.values.description}
                    onChange={(event) => {
                      const value = event.target.value;
                      const remaining = 1000 - value.length;
                      if (remaining >= 0) {
                        formik.setFieldValue('description', value);
                        setDescriptionCharactersLeft(remaining);
                      }
                    }}
                    size='small'
                    multiline
                    rows={6}
                  />
                  <Description
                    sx={{
                      color: !descriptionCharactersLeft
                        ? Color.RED
                        : Color.WHITE,
                      textAlign: 'right',
                    }}
                  >
                    {t('symbolsLeft', { count: descriptionCharactersLeft })}
                  </Description>
                </StyledInputContainer>
              </UploadAreaContainer>
              <UploadAreaContainer
                style={{ marginTop: isMobile ? '16px' : '30px' }}
              >
                <StyledSection>
                  <Subtitle>{t('projectsWebsite')}</Subtitle>
                  <StyledLinksContainer>
                    <StyledLinksInput
                      size='small'
                      placeholder='yoursite.io'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Language style={{ color: Color.WHITE }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledLinksContainer>
                </StyledSection>
              </UploadAreaContainer>
              <StyledSection>
                <Subtitle>{t('creatorFees')}</Subtitle>
                <Description>{t('creatorFeesDescription')}</Description>
                <Grid
                  container
                  gap={isMobile ? '16px' : '32px'}
                  marginBottom={'10px'}
                >
                  <Grid
                    item
                    xs={8}
                    sx={{ width: isMobile ? '80%' : 'inherit' }}
                  >
                    <StyledInput
                      size='small'
                      name={`royalties_address`}
                      placeholder={t('creatorEarnings')!}
                      value={formik.values.royalties_address}
                      onChange={(e) => {
                        setTimeout(() => {
                          validateCreatorFeesAddress(e.target.value);
                        }, 200);
                        formik.setFieldValue(
                          `royalties_address`,
                          e.target.value.trim()
                        );
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{ width: isMobile ? '80%' : 'inherit' }}
                  >
                    <StyledInput
                      size='small'
                      name={`royalties_percent`}
                      value={formik.values?.royalties_percent}
                      placeholder={'0 %'}
                      onChange={(e) => {
                        const result = e.target.value.replace(/[^\d\.]/g, '');
                        if (Number(result) <= 10 && result.length <= 4) {
                          result?.match(/\./g)?.length! > 1
                            ? formik.setFieldValue(
                                `royalties_percent`,
                                result.slice(0, result.lastIndexOf('.'))
                              )
                            : result === '00' ||
                              (result.length === 2 &&
                                result[0] === '0' &&
                                result[1] !== '.')
                            ? formik.setFieldValue(`royalties_percent`, '0')
                            : result[0] === '.'
                            ? formik.setFieldValue(`royalties_percent`, '')
                            : formik.setFieldValue(`royalties_percent`, result);
                        }
                      }}
                    />
                  </Grid>
                  <IconButton
                    onClick={async () => {
                      await formik.setFieldValue(`royalties_address`, '');
                      await formik.setFieldValue(`royalties_percent`, '');
                    }}
                  >
                    <Delete sx={{ color: Color.WHITE }} />
                  </IconButton>
                </Grid>

                {isAddressErr && <TotalErr>Invalid address</TotalErr>}
              </StyledSection>
              <StyledSection>
                <Subtitle>{t('blockchain')}</Subtitle>
                <Description>{t('blockchainDescription')}</Description>
                <StyledFormControl sx={{ width: '100%' }}>
                  {!isOpenNetworkSelect && !formik.values.network && (
                    <StyledInputLabel id='network-select'>
                      {t('selectBlockchain')}
                    </StyledInputLabel>
                  )}
                  <StyledNetworkSelect
                    name='network'
                    labelId='network-select'
                    id='network-select'
                    placeholder='Select Blockchain'
                    onChange={formik.handleChange}
                    onOpen={() => setIsOpenNetworkSelect(true)}
                    onClose={() => setIsOpenNetworkSelect(false)}
                    value={formik.values.network}
                    MenuProps={{
                      MenuListProps: {
                        style: selectMenuListStyle,
                      },
                      PaperProps: {
                        style: selectPaperStyle,
                      },
                    }}
                  >
                    {generateNetworksArray().map((network) => (
                      <StyledMenuItem
                        key={network.id}
                        value={network.id}
                      >
                        <Stack
                          direction='row'
                          alignItems='center'
                          gap={1}
                        >
                          <NetworkIcon src={getNetworkIcon(network.id)} />
                          {network.name}
                        </Stack>
                      </StyledMenuItem>
                    ))}
                  </StyledNetworkSelect>
                </StyledFormControl>
              </StyledSection>
              {/* <StyledSection>
              <Grid
                container
                alignItems={'center'}
                columnGap='40px'
              >
                <Grid item>
                  <Subtitle>{t('explicitContent')}</Subtitle>
                  <Grid container>
                    <Description>{t('explicitContentDescription')}</Description>
                    <Infotip
                      title={<Typography p={1}>{t('explicitTip')}</Typography>}
                      placement='top-start'
                    >
                      <HelpOutlineRounded
                        fontSize='small'
                        sx={{
                          color: 'grey',
                          '&:hover': { color: 'lightgrey' },
                        }}
                      />
                    </Infotip>
                  </Grid>
                </Grid>
                <StyledLiquiditySwitch />
              </Grid>
            </StyledSection> */}
              {uniqueNamingError && (
                <ErrorContainer>
                  <AlertIcon />
                  <ErrorText>{t('collectionNameOrURLError')}</ErrorText>
                </ErrorContainer>
              )}
              {creatingError && (
                <ErrorContainer>
                  <AlertIcon />
                  <ErrorText>
                    {t('sthWentWrongWhileCreatingTryAgainLater', {
                      item: 'NFT collection',
                    })}
                  </ErrorText>
                </ErrorContainer>
              )}
            </PaddingContainer>
            <ButtonContainer>
              <StyledButton
                type='submit'
                disabled={disabled}
              >
                {t('create')}
              </StyledButton>
            </ButtonContainer>
          </form>
        </StyledConatiner>
      </ContainerWrapper>

      {openWaitingPopup && (
        <LiquidityWaitingPopup
          isOpen={openWaitingPopup}
          onClose={() => setOpenWaitingPopup(false)}
          fromNFT
          createText={t('itemIsCreating', { item: t('nftCollection') })}
        />
      )}
    </>
  );
};
export default CreateNftCollection;
